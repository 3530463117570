<template>
  <Card
    class="tariff"
    :color="
      item.slug === 'black' || item.slug === 'premium' ? 'black' : 'white'
    "
  >
    <div class="tariff-head">
      <div class="tariff-title">
        {{ item.name }}
        <!-- <span
          v-if="item.hit"
          class="tariff-label"
          :style="{ backgroundColor: item.hit.color }"
        >
          {{ item.hit.text }}
        </span> -->
      </div>
      <div class="tariff-options">
        <div v-if="item.internetPackage?.value" class="tariff-option">
          <strong v-if="item.internetPackage?.value">
            {{ item.internetPackage.value }}
            {{ item.internetPackage.uom }}
          </strong>
          <span v-if="item.internetPackage?.bonus"
            >+{{ item.internetPackage.value }}
            {{ item.internetPackage.uom }} каждый 3-й <span>месяц</span></span
          >
        </div>
        <div v-if="item.minutes?.value" class="tariff-option">
          <strong v-if="item.minutes?.value">
            {{ item.minutes.value }} минут
          </strong>
          <span v-if="item.minutes?.frontName">
            {{ item.minutes.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
        <div v-else-if="minutesPackage" class="tariff-option">
          <strong v-if="minutesPackage?.value">
            {{ minutesPackage.value }} минут
          </strong>
          <span v-if="minutesPackage?.frontName">
            {{ minutesPackage.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
      </div>
      <TariffFeatures :item="item" />
    </div>
    <div class="tariff-foot">
      <div v-if="internetPackage" class="tariff-home">
        <div class="tariff-home__info">
          <span>Домашний интернет:</span>
          <span>от {{ internetPackage.summaryWeight }} Мбит/с</span>
        </div>
        <div class="tariff-home__control">
          <ToggleButton @update="showCost = !showCost" />
        </div>
      </div>
      <div v-if="item.firstMonthAbonentFee" class="tariff-price">
        <span class="stroke" v-if="item.hitAbonentFee"
          >{{ item.hitAbonentFee }} ₽</span
        >
        {{ item.firstMonthAbonentFee }} <span>₽/мес</span>
      </div>
      <div v-else class="tariff-price">
        <span class="stroke" v-if="showCost"
          >{{ item.internetTele2_home.cost + item.price }} ₽</span
        >
        {{ item.price }} <span>₽/мес</span>
      </div>
      <Button
        v-if="showMoreBtn(item.slug)"
        :to="{
          path: `/detail/${item.slug}`,
          query: $route.query,
        }"
        class="tariff-button"
      >
        Подробнее
      </Button>
    </div>
  </Card>
</template>

<script>
import Button from "./Button.vue";
import Card from "./Card.vue";
import TariffFeatures from "./TariffFeatures.vue";
import ToggleButton from "./ToggleButton.vue";

export default {
  props: {
    item: Object,
  },
  computed: {
    unlimitedTraffic() {
      return this.item.parameters
        ?.filter((e) => e.blockAlignment === "unlimitedTraffic")
        .sort((a, b) => b.frontWeight - a.frontWeight);
    },
    freeTraffic() {
      const { containerParams } = this.item;

      if (!containerParams) {
        return null;
      }

      const icons = [
        ...(containerParams.messengers ?? []),
        ...(containerParams.socialNetworks ?? []),
        ...(containerParams.videoServices ?? []),
      ].filter((e) => e.selectedIcon);

      if (!icons.length) {
        return null;
      }

      return {
        frontName: this.item.containerParams?.internetFree || "",
        icons,
      };
    },
    internetPackage() {
      return this.item.parameters?.find((e) => e.key === "пакетИнтернета");
    },
    minutesPackage() {
      return this.item.parameters?.find(
        (e) => e.key === "пакетМинутНаДругиеМобРоссии"
      );
    },
  },
  components: {
    ToggleButton,
    Card,
    Button,
    TariffFeatures,
  },
  data() {
    return {
      showCost: false,
    };
  },
  methods: {
    showMoreBtn(slug) {
      return [
        "enough",
        "everywhere-online",
        "black",
        "my-online",
        "my-online-plus",
        "my-conversation",
        "prozrachnie-granicy-m",
        "prozrachnie-granicy-l",
      ].includes(slug);
    },
    remakeHREF(str) {
      if (str.includes("Selection")) {
        return str.replace(
          "/bolshe/selection?pageParams=view%3Dlanding",
          "https://msk.t2.ru/bolshe/selection?pageParams=view%3Dlanding"
        );
      }
      return str;
    },
    getSocial(features) {
      // remove if link fix
      const brokenString =
        '<a href="/option/4g-turbo">4G Turbo</a> и <a href="https://selection.t2.ru/">привилегии программы t2 Selection</a>';
      const broken = features.advantages.find(
        (a) => a.frontName === brokenString
      );
      if (broken) {
        broken.frontName =
          '<a href="https://t2.ru/option/4g-turbo" target="_blank">4G Turbo</a> и <a href="https://selection.t2.ru/" target="_blank">привилегии программы t2 Selection</a>';
      }
      // end remove if link fix

      const sortedByPriority = features.advantages
        .concat(features.gamingAdvantages)
        .sort((a, b) => a.priority - b.priority);
      return [
        features.messengers,
        features.socialNetworks,
        ...sortedByPriority,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff {
  display: grid;
  align-content: space-between;
  gap: 16px;
  @include md {
    gap: 24px;
  }
  &.black {
    color: var(--colorWhite);
    .tariff-option {
      color: #bfbfbf;
      &:deep(strong) {
        color: var(--colorWhite);
      }
    }
    &:deep(.tariff-features__pic) {
      li {
        background-color: var(--colorBlack);
      }
    }
  }
  &-head {
  }
  &-title {
    font-family: var(--fontSecondary);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
    font-weight: 800;
    display: inline-grid;
    grid-auto-flow: column;
    gap: 12px;
    align-items: center;
  }
  &-label {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 10px;
    font-family: var(--fontPrimary);
    font-weight: 400;
    color: var(--colorWhite);
  }
  &-options {
    display: grid;
    gap: 12px;
    margin-top: 16px;
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
    &_3 {
      @include md {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  &-option {
    color: #808080;
    &:deep(strong) {
      display: block;
      font-size: var(--sizeHeading3);
      line-height: var(--lineHeightHeading3);
      font-family: var(--fontSecondary);
      font-weight: 800;
      color: var(--colorBlack);
      margin-bottom: 4px;
      white-space: nowrap;
    }
  }
  &-features {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    display: grid;
    gap: var(--widthGapV);
    justify-content: space-between;
    @include md {
      margin-top: 24px;
      font-size: var(--sizeText);
      line-height: var(--lineHeightText);
      grid-auto-flow: column;
    }
    ul {
      @include reset-list;
    }
    &__pic {
      display: flex;
      align-items: flex-start;
      li {
        + li {
          margin-left: -8px;
        }
        background-color: var(--colorWhite);
        padding: 2px;
        border-radius: 64px;
        img {
          display: block;
        }
        &.more {
          background-color: #999ea8 !important;
          width: 36px;
          height: 36px;
          color: var(--colorWhite);
          display: grid;
          place-items: center;
          font-size: 12px;
        }
      }
    }
  }
  &-home {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    &__info {
      span {
        display: block;
      }
    }
    &__control {
    }
  }
  &-button {
    justify-content: space-between;
    &:deep(.stroke) {
      position: relative;
      font-size: 12px;
      line-height: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 2px solid var(--colorPink);
        margin-top: -1px;
        transform: rotate(-10deg);
      }
    }
  }
  &-foot {
    display: grid;
    gap: 8px;
    @include md {
      gap: 12px;
    }
  }
  &-price {
    display: block;
    font-size: var(--sizeHeading3);
    line-height: var(--lineHeightHeading3);
    font-family: var(--fontSecondary);
    font-weight: 800;
    margin-bottom: 4px;
    white-space: nowrap;
    position: relative;
    @include md {
      padding-top: 16px;
    }
    &:deep(.stroke) {
      font-size: 12px;
      line-height: 10px;
      position: relative;
      @include md {
        position: absolute;
        top: 0;
        left: 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 2px solid var(--colorPink);
        margin-top: -1px;
        transform: rotate(-10deg);
      }
    }
  }
}
</style>
